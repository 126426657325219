import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { MfaList, PageWrapper } from '@Components';
import { MfaElement } from '@Models';

interface MfaProps {
  phones: MfaElement[];
}

const Mfa = ({ phones }: MfaProps) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white w-full h-full">
      <PageWrapper>
        <div className="flex">
          <div className="text-3xl font-semibold mb-6  text-black-100">{t('Mfa.Title')}</div>
        </div>
        <div className="border border-gray-100 border-solid rounded-xl">
          <div className="flex md:flex-row mx-6 my-4 items-center flex-col">
            <span className="font-bold text-xl justify-start text-black-100">
              {`${t('Mfa.Message')} (${phones?.length ?? 0})`}
            </span>
          </div>
          <MfaList phones={phones ?? []} />
        </div>
      </PageWrapper>
    </div>
  );
};

export default Mfa;
