import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonNew, Column, ModalMfa, Table } from '@Components';
import { MfaElement } from '@Models';

const MfaList = ({ phones }: { phones: MfaElement[] }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');

  const builderCell = (type: string, accessor: string, value: string, original: MfaElement): JSX.Element => {
    if (type === 'phone') {
      return <span className="font-semibold">{value}</span>;
    }
    if (type === 'button') {
      return (
        <div className="flex justify-end">
          <ButtonNew
            onClick={() => {
              setId(original.id);
              setOpen(true);
            }}
            isSubmit={false}
            variant="primary">
            {t('Mfa.Code')}
          </ButtonNew>
        </div>
      );
    }
    return <div key={accessor}>{value}</div>;
  };

  const getValueCell = (
    row: {
      original: MfaElement;
    },
    key: keyof MfaElement,
    type: string,
  ): string | MfaElement => {
    const cellValue = row?.original?.[key];
    if (type === 'button') return row.original;
    return cellValue;
  };

  const getColumns = (): Column[] => {
    const columns = [
      {
        label: t('Mfa.Phone'),
        accessor: 'title',
        key: 'phone_number',
        type: 'phone',
        width: 'w-[30%]',
      },
      {
        label: t('Mfa.Description'),
        accessor: 'description',
        key: 'description',
        type: 'description',
        width: 'w-[30%]',
      },
      {
        label: '',
        type: 'button',
        accessor: 'action',
        key: 'phone_provider',
        width: 'w-[40%]',
      },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }: { row: { original: MfaElement } }) => {
          const cellValue = getValueCell(row, column.key as keyof MfaElement, column.type);
          return builderCell(column.type, column.accessor, cellValue as string, row.original);
        },
        width: column.width,
      };
    });
    return headers;
  };

  return (
    <div className="flex mt-[10px]">
      <Table columns={getColumns()} data={phones} pageSize={9} pagination />
      <ModalMfa isOpen={open} id={id} onClose={() => setOpen(false)} />
    </div>
  );
};

export default MfaList;
