import mime from 'mime';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { showErrorToast, showSuccessToast } from '@Helpers';

import { sendCustomEvent, sendErrorEvent } from '@Analytics';
import { open_icon } from '@Assets';
import { ButtonNew, Column, Table } from '@Components';
import { bopsApi } from '@Network';
import { InputFile } from '../FileLinkCard/fileLinkCard.styles';

interface ConfigurationLink {
  title: string;
  description: string;
  link: string;
}

const ConfigurationList = ({
  configures,
  setLoading,
  refetch,
}: {
  configures: ConfigurationLink[];
  setLoading: (loading: boolean) => void;
  refetch: () => void;
}) => {
  const { t } = useTranslation();

  const buildBadges = (value: string): JSX.Element => {
    const badgeMapping: { [key: string]: { label: string; color: string; colorDot: string; colorLabel: string } } = {
      ACTIVE: {
        label: t('Configuration.Active'),
        color: 'bg-green-100 border-green-200',
        colorDot: 'bg-green-400',
        colorLabel: 'text-green-500',
      },
    };

    return (
      <div className={`${badgeMapping?.[value]?.color} px-[8px] py-[3px] border-solid rounded-lg flex w-fit`}>
        <div className={`${badgeMapping?.[value]?.colorDot}  h-1.5 w-1.5 flex self-center rounded`} />
        <span className={`${badgeMapping?.[value]?.colorLabel} ml-[6px] font-medium`}>
          {badgeMapping?.[value]?.label}
        </span>
      </div>
    );
  };

  const openFileInNewWindow = (link: string) => {
    window.open(link, '_blank');
  };

  const uploadToAWS = async (uploadUrl: string, selectedFile, contentType) => {
    const requestOptions = {
      method: 'PUT',
      headers: { 'content-type': contentType },
      body: selectedFile,
    };
    const response = await fetch(uploadUrl, requestOptions);
    if (response.status === 200) {
      showSuccessToast(t('Configuration.Success'));
      refetch();
    } else {
      const textResponse = await response.text();
      showErrorToast(t('Configuration.Error'));
      sendErrorEvent(`unable to upload file to AWS S3. ${textResponse}`);
      throw new Error(`Invalid response: ${response.status}`);
    }
  };

  const uploadFile = (input: any, value: string | { title: string; link: string }): void => {
    const title = typeof value === 'object' ? value?.title : value;
    setLoading(true);
    const uploadAsync = async () => {
      const file = input?.target?.files?.[0];
      const contentType = mime.getType(file?.name) || 'text/csv';
      const fileType = title.split(' ').join('_').toUpperCase();

      const uploadResponse = await bopsApi.requestUploadConfigurationFile(
        fileType,
        contentType,
        file?.name?.split('.').pop(),
      );

      const { data } = uploadResponse;
      return uploadToAWS(data, file, contentType).catch((e) => {
        sendErrorEvent(`unable to upload file to AWS S3. ${e}`);
        throw new Error(`Invalid response`);
      });
    };

    uploadAsync()
      .then(() => {
        sendCustomEvent('CONFIGURATION_FILE', { type: 'UPLOAD', title });
        showSuccessToast(t('Configuration.Success'));
        setLoading(false);
      })
      .catch((e) => {
        sendErrorEvent(`unable to request upload file. ${e}`);
        setLoading(false);
        showErrorToast(t('Configuration.Error'));
      });
  };

  const builderCell = (
    type: string,
    accessor: string,
    value: string | { title: string; link: string },
    icon: string,
  ): JSX.Element => {
    if (type === 'badge') return buildBadges('ACTIVE');
    if (type === 'icon')
      return (
        <div className="flex  items-center">
          <img src={icon} alt="icon" className="w-[25px] mr-2" />
          <span>{value}</span>
        </div>
      );
    if (type === 'label') {
      return <span className="font-semibold">{value}</span>;
    }
    if (type === 'button') {
      if (value && typeof value !== 'object') return <div />;
      const title = typeof value === 'object' ? value?.title : '';
      const link = typeof value === 'object' ? value?.link : '';
      return (
        <div className="flex gap-2">
          <label
            htmlFor={title}
            className="flex items-center justify-center px-3 rounded-md m-auto mr-2 text-sm font-semibold h-10 w-auto border-solid border-gray-150 shadow-md text-gray-300 hover:border-bg-gray-150 hover:bg-gray-50 cursor-pointer hover:shadow-sm">
            {t('Configuration.Replace')}
            <InputFile
              id={title}
              type="file"
              className="hidden"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => uploadFile(e, value)}
            />
          </label>
          <ButtonNew onClick={() => openFileInNewWindow(link)} variant="primary" isSubmit={false} className="w-[90px]">
            <div className="flex items-center justify-center">
              <img src={open_icon} alt="icon" className="w-[15px] mr-[5px]" />
              {t('Configuration.Open')}
            </div>
          </ButtonNew>
        </div>
      );
    }
    return <div key={accessor}>{value}</div>;
  };

  const getValueCell = (
    row: {
      original: {
        [key: string]: any;
      };
    },
    key: string,
    type: string,
  ): string | { title: string; link: string } => {
    const cellValue = row?.original?.[key];
    if (type === 'button') return row?.original as { title: string; link: string };
    return cellValue as string | { title: string; link: string };
  };

  const getColumns = (): Column[] => {
    const columns = [
      {
        label: t('Configuration.Name'),
        accessor: 'title',
        key: 'title',
        type: 'label',
        width: 'w-[30%]',
      },
      {
        label: t('Configuration.Status'),
        accessor: 'credential.status',
        key: 'status',
        type: 'badge',
        width: 'w-[15%]',
      },
      {
        label: t('Configuration.LastUpdated'),
        accessor: 'last_updated_date',
        key: 'last_updated_date',
        type: 'general',
        width: 'w-[15%]',
      },
      {
        label: t('Configuration.LastProcessed'),
        accessor: 'last_processed_date',
        key: 'last_processed_date',
        type: 'general',
        width: 'w-[20%]',
      },
      {
        label: '',
        type: 'button',
        accessor: 'action',
        key: 'status',
        width: 'w-[20%]',
      },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }) => {
          const cellValue = getValueCell(row, column.key, column.type);
          return builderCell(column.type, column.accessor, cellValue, row?.original?.icon);
        },
        width: column.width,
      };
    });
    return headers;
  };

  return (
    <div className="flex mt-[10px]">
      <Table columns={getColumns()} data={configures} pageSize={9} pagination />
    </div>
  );
};

export default ConfigurationList;
