import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '@Helpers';

import { ButtonNew, Input, ModalComponent, ModalLoading, ModalSuccess } from '@Components';
import { bopsApi } from '@Network';

interface SupplierModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchSuppliers: () => void;
  setloading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

interface FormFields {
  gln: string;
  displayName: string;
}

const SupplierAddModal = ({ isOpen, onClose, fetchSuppliers, setloading, setSearch }: SupplierModalProps) => {
  const { t } = useTranslation();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [name, setName] = useState('');
  const [errorExist, setErrorExist] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFields>();

  useEffect(() => {
    reset();
    setErrorExist('');
  }, [isOpen]);

  const onSubmit = (data: FormFields) => {
    const { gln, displayName } = data;
    setName(displayName);
    setOpenLoading(true);
    setloading(true);
    setErrorExist('');
    bopsApi
      .postSupplier({
        gln,
        display_name: displayName,
      })
      .then(() => {
        setloading(false);
        onClose();
        setOpenLoading(false);
        setOpenSuccess(true);
        fetchSuppliers();
        setSearch('');
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.detail;
        const textError = t(errorMessage === 'Already exists' ? 'Supplier.Exist' : 'Supplier.Error');
        setloading(false);
        setOpenLoading(false);
        showErrorToast(textError);
        if (errorMessage === 'Already exists') setErrorExist(textError);
      });
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={onClose} title={t('Supplier.TitleModal')} closeClickOutside={false}>
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pr-[20px]">
              <span className="text-black-200 text-sm" id="email">
                GLN*
              </span>
              <Input
                type="number"
                id="gln"
                className={`${
                  errors?.gln || errorExist ? 'border-red focus:border-red' : 'focus:border-gray-400'
                } mb-2`}
                {...register('gln', {
                  required: `GLN ${t('Global.Required')}`,
                })}
              />
            </div>
            {(errors?.gln || errorExist) && <p className="text-sm text-red">{errors?.gln?.message ?? errorExist}</p>}
            <div className="pr-[20px]">
              <span className="text-black-200 text-sm" id="email">
                {`${t('Supplier.Name')}*`}
              </span>
              <Input
                type="text"
                id="displayName"
                className={`${errors?.displayName ? 'border-red focus:border-red' : 'focus:border-gray-400'} mb-2`}
                {...register('displayName', {
                  required: `${t('Supplier.Name')} ${t('Global.Required')}`,
                })}
              />
            </div>
            {errors?.displayName && <p className="text-sm text-red">{errors?.displayName?.message}</p>}

            <div className="flex gap-2 justify-center mt-4">
              <ButtonNew
                className="w-full bg-white"
                onClick={() => {
                  onClose();
                }}
                isSubmit={false}>
                {t('Global.Canceled')}
              </ButtonNew>
              <ButtonNew isSubmit variant="primary" className="w-full">
                <div className="text-white"> {t('Supplier.Add')} </div>
              </ButtonNew>
            </div>
          </form>
        </div>
      </ModalComponent>

      <ModalSuccess isOpen={openSuccess} onClose={() => setOpenSuccess(false)}>
        <div className="text-center -mt-4">
          <span className="font-semibold text-[18px] text-center"> {name}</span>
          <span className="text-[18px]"> {t('Supplier.AddMessage')} </span>
        </div>
      </ModalSuccess>

      <ModalLoading isOpen={openLoading} onClose={() => setOpenLoading(false)}>
        <div className="text-center -mt-[9px]">
          <span className="font-semibold text-center"> {name}</span> {t('Supplier.LoadingCreate')}
        </div>
      </ModalLoading>
    </>
  );
};

export default SupplierAddModal;
