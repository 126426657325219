import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { showErrorToast } from '@Helpers';

import { ButtonNew, ModalComponent, ModalLoading, ModalSuccess, Select } from '@Components';
import { Options } from '@Models';
import { bopsApi } from '@Network';
import { AppDispatch, tableServerRevision } from '@Store';
import { SelectOptions } from '../Select/select';

interface UserModalProps {
  isOpen: boolean;
  onClose: () => void;
  fetchUsers: () => void;
  IncrementEvent: (event: string) => void;
  clientValue: Options | undefined;
  clients: Options[];
  email: string;
  oldAccount: string;
}

interface FormFields {
  client: string;
}

const UserEditModal = ({
  isOpen,
  onClose,
  fetchUsers,
  clientValue,
  IncrementEvent,
  clients,
  email,
  oldAccount,
}: UserModalProps) => {
  const { t } = useTranslation();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFields>();

  useEffect(() => {
    reset();
  }, [isOpen]);

  const onSubmit = (data: FormFields) => {
    const { client } = data;
    setOpenLoading(true);

    bopsApi
      .changeAccountUser(email, oldAccount, client)
      .then(() => {
        onClose();
        setOpenLoading(false);
        setOpenSuccess(true);
        setTimeout(() => {
          IncrementEvent('usersEvent');
          fetchUsers();
        }, 3000);
      })
      .catch((error) => {
        setOpenLoading(false);
        const errorMessage = t('User.ErrorChangeAccount');
        showErrorToast(t(errorMessage));
      });
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={onClose} title={t('User.TitleEditModal')} closeClickOutside={false}>
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="text-sm mt-4 mb-10">
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={clientValue?.value}
                name="client"
                render={({ field: { onChange } }) => (
                  <Select
                    placeholder={t('User.Select')}
                    defaultValue={clientValue}
                    error={!!errors.client}
                    options={clients}
                    onChange={(val: SelectOptions) => {
                      onChange(val?.value || '');
                    }}
                    isClearable
                  />
                )}
              />
              {errors.client && <p className="text-sm text-red">{`${t('User.Client')} ${t('Global.Required')}`} </p>}
            </div>
            <div className="flex gap-2 justify-center mt-4 w-full">
              <ButtonNew
                onClick={() => {
                  onClose();
                }}
                className="w-full bg-white"
                isSubmit={false}>
                {t('Global.Canceled')}
              </ButtonNew>
              <ButtonNew isSubmit variant="primary" className="w-full">
                <div className="text-white"> {t('User.Edit')} </div>
              </ButtonNew>
            </div>
          </form>
        </div>
      </ModalComponent>

      <ModalSuccess isOpen={openSuccess} onClose={() => setOpenSuccess(false)}>
        <div className="text-center">
          <span className="text-[18px]"> {t('User.EditSuccess')} </span>
        </div>
      </ModalSuccess>

      <ModalLoading isOpen={openLoading} onClose={() => setOpenLoading(false)}>
        <div className="text-center -mt-[9px]">{t('User.LoadingEdit')}</div>
      </ModalLoading>
    </>
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { IncrementEvent } = tableServerRevision;

  return {
    IncrementEvent: (event: string) => dispatch(IncrementEvent(event)),
  };
};

export default connect(null, mapDispatchToProps)(UserEditModal);
