import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Loading } from '@Components';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import Configure from './configureLink';

const ConfigureContainer = (props) => {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useFetchData(bopsApi.getConfigurationLinks);

  if (isLoading) return <Loading title={t('Configuration.LoadingLink')} />;

  if (error) {
    return <ErrorMessage internalMessage={`Unable to get configuration links: ${error}`} />;
  }
  return <Configure configurationLinks={data || []} refetch={refetch} />;
};

export default ConfigureContainer;
