// Button.tsx
import React from 'react';

type ButtonProps = {
  onClick?: () => void;
  children: React.ReactNode;
  isSubmit: boolean;
  variant?: 'primary';
  disabled?: boolean;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({ onClick, children, isSubmit, variant, disabled = false, className = '' }) => {
  const getButtonClass = () => {
    switch (variant) {
      case 'primary':
        return 'bg-primary text-white hover:bg-primaryDark border-solid border-primaryDark';
      default:
        return 'bg-gray-100 border-solid border-gray-150';
    }
  };

  return (
    <button
      onClick={onClick}
      type={isSubmit ? 'submit' : 'button'}
      className={`border rounded-md p-[12px] disabled:bg-gray-100 border-solid hover:shadow-lg shadow-md cursor-pointer ${getButtonClass()} ${className}`}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default Button;
