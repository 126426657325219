import { Tooltip as ReactTooltip } from 'react-tooltip';

const buildTooltip = ({ id, content }: { id: string; content: string }) => {
  return <ReactTooltip id={id} positionStrategy="fixed" place="top" content={content} offset={18} />;
};

const getColorPill = (color: string) => {
  switch (color) {
    case 'gray':
      return 'bg-gray-50 border-gray-100';
    case 'green':
      return 'bg-green-100 border-green-200';
    case 'orange':
      return 'bg-orange-100 border-orange-200';
    case 'red':
      return 'bg-red-50 border-red-200';
    case 'blue':
      return 'bg-blue-100 border-blue-800';
    case 'yellow':
      return 'bg-yellow-100 border-yellow-200';
    default:
      return `bg-${color} border-${color}-200`;
  }
};

const getColorText = (color: string) => {
  switch (color) {
    case 'gray':
      return 'text-gray-400';
    case 'green':
      return 'text-green-500';
    case 'orange':
      return 'text-orange-800';
    case 'red':
      return 'text-red-500';
    case 'blue':
      return 'text-blue-800';
    default:
      return `text-${color}`;
  }
};
const getColorDot = (color: string) => {
  switch (color) {
    case 'gray':
      return 'bg-gray-400';
    case 'green':
      return 'bg-green-400';
    case 'orange':
      return 'bg-orange-600';
    case 'red':
      return 'bg-red-500';
    case 'blue':
      return 'text-blue-800';
    default:
      return `text-${color}`;
  }
};

const Badge = ({
  color,
  value,
  showDot = false,
  tooltipId,
  toolInfo,
}: {
  color: string;
  value: string;
  showDot?: boolean;
  tooltipId?: string;
  toolInfo?: string;
}) => {
  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        className={`${getColorPill(color)} px-[10px] py-[8px] border-solid rounded-md flex w-fit border-[1px]`}>
        {showDot && <div className={`${getColorDot(color)} h-1.5 w-1.5 flex self-center rounded mr-[6px]`} />}
        <span className={`${getColorText(color)} text-center font-medium`}>{value}</span>
      </div>

      {toolInfo && tooltipId && buildTooltip({ id: tooltipId, content: toolInfo })}
    </>
  );
};

export default Badge;
