import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage, Loading } from '@Components';
import { useFetchData } from '@CustomHooks';
import { bopsApi } from '@Network';
import Mfa from './mfa';

const MfaContainer = (props) => {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useFetchData(bopsApi.getPhonesMfa);

  if (isLoading) return <Loading title={t('')} />;

  if (error) {
    return <ErrorMessage internalMessage={`Unable to get mfa codes: ${error}`} />;
  }
  return <Mfa phones={data || []} />;
};

export default MfaContainer;
