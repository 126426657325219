import { useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import { showErrorToast } from '@Helpers';

import {
  Badge,
  Column,
  ConfirmationModal,
  MenuComponent,
  ModalLoading,
  ModalSuccess,
  TableServer,
  UserEditModal,
} from '@Components';
import { ClientObject, Options, UserType } from '@Models';
import { bopsApi } from '@Network';
import { AppDispatch, RootState, tableServerRevision, usersOperations } from '@Store';

const UserList = ({
  usersData,
  clientsObjects,
  total,
  client,
  emailQuery,
  pageSize,
  setTotal,
  IncrementEvent,
  fetchUsers,
  clients,
}: {
  usersData: UserType[];
  clientsObjects: ClientObject;
  total: number;
  client: string;
  emailQuery: string;
  pageSize: number;
  IncrementEvent: (event: string) => void;
  fetchUsers: () => void;
  setTotal: (total: number) => void;
  clients: Options[];
}) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [email, setEmail] = useState('');
  const [blocked, setBlocked] = useState(false);
  const [index, setIndex] = useState(0);
  const dispatch = useDispatch();
  const [oldAccount, setOldAccount] = useState('');
  const [changePassword, setChangePassword] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const changePasswordUser = () => {
    setIsModalOpen(false);
    setChangePassword(false);
    setOpenLoading(true);
    bopsApi
      .changePasswordUser(email)
      .then(() => {
        setOpenSuccess(true);
        setOpenLoading(false);
      })
      .catch((error) => {
        showErrorToast(t('User.ErrorChangePassword'));
        setOpenLoading(false);
      });
  };

  const blockorUnblockUser = () => {
    setIsModalOpen(false);
    bopsApi
      .blockUnblockUser(email, !blocked, oldAccount)
      .then(() => {
        IncrementEvent('usersEvent');
      })
      .catch((error) => {
        showErrorToast(t('User.ErrorBlock'));
      });
  };

  const getValueCell = (row, key: string): string | any[] => {
    let cellValue = row?.original[key];
    if (key === 'app_metadata' && clientsObjects) {
      const keyClient = row?.original?.app_metadata?.account;
      if (Array.isArray(keyClient)) {
        return keyClient.map((keyId: { id: string }) => clientsObjects?.[keyId.id] ?? keyId.id);
      }
      cellValue = clientsObjects?.[keyClient];
    }
    if (key === 'last_login') cellValue = cellValue ? moment(cellValue).format('l') : t('User.Never');
    return cellValue;
  };

  const builderCell = (
    type: string,
    label: string,
    value,
    id: string,
    emailUser: string,
    account: string,
    block: boolean,
  ): JSX.Element => {
    if (type === 'string' || (type === 'badge' && !value)) return <div key={label}>{value}</div>;

    if (type === 'badge' && value) {
      if (Array.isArray(value)) {
        return (
          <div className="flex gap-2 flex-wrap">
            {value.map((item: string, key: number) => (
              <Badge
                color="gray"
                value={item.length > 20 ? `${item.slice(0, 20)}...` : item}
                key={item}
                tooltipId={`${key} ${item}`}
                toolInfo={item.length > 20 ? item : undefined}
              />
            ))}
          </div>
        );
      }
      return <Badge color="gray" value={value} />;
    }

    if (type === 'badge-status') {
      return <Badge color={value ? 'gray' : 'green'} value={value ? t('User.Blocked') : t('User.Active')} showDot />;
    }
    return (
      <MenuComponent
        className=""
        options={[
          {
            id: 'block',
            label: block ? t('User.UnBlockLabel') : t('User.BlockLabel'),
            onAction: () => {
              setIsModalOpen(true);
              setBlocked(block);
              setIndex(+id);
              setEmail(emailUser);
              setOldAccount(account);
            },
          },
          {
            id: 'changeAccount',
            label: t('User.ChangeAccount'),
            onAction: () => {
              setOpenEdit(true);
              setEmail(emailUser);
              setOldAccount(account);
            },
          },
          {
            id: 'changePassword',
            label: t('User.ChangePassword'),
            onAction: () => {
              setIsModalOpen(true);
              setChangePassword(true);
              setEmail(emailUser);
            },
          },
        ]}
      />
    );
  };

  const filterQuery = useMemo(() => {
    return `email=${emailQuery}&account=${client}`;
  }, [emailQuery, client]);

  const getColumns = (): Column[] => {
    const columns = [
      { label: t('User.Email'), accessor: 'email', key: 'email', type: 'string', width: 'w-[25%]' },
      {
        label: t('User.Client'),
        accessor: 'app_metadata.account',
        key: 'app_metadata',
        type: 'badge',
        width: 'w-[40%]',
      },
      { label: t('User.LastLogin'), accessor: 'last_login', key: 'last_login', type: 'string', width: 'w-[15%]' },
      {
        label: t('User.Status'),
        accessor: 'blocked',
        key: 'blocked',
        type: 'badge-status',
        width: 'w-[10%]',
      },
      { label: '', accessor: 'block', key: 'status', type: 'button', width: 'w-[10%]' },
    ];

    const headers = columns.map((column) => {
      return {
        Header: column.label,
        accessor: column.accessor,
        Cell: ({ row }) => {
          const cellValue = getValueCell(row, column.key);
          return builderCell(
            column.type,
            column.label,
            cellValue,
            row.id,
            row?.original?.email,
            row?.original?.app_metadata?.account,
            row?.original?.blocked,
          );
        },
        width: column.width,
      };
    });
    return headers;
  };

  return (
    <>
      <TableServer
        sortable
        columns={getColumns()}
        dataResult={usersData}
        pageSize={pageSize}
        pagination
        total={total}
        setTotal={setTotal}
        filters={filterQuery}
        startPagination={0}
        endpoint="getUsers"
        indexPagination="users"
        incrementName="usersEvent"
        disableSortFields={['app_metadata.account']}
      />

      <ConfirmationModal
        confirmText={
          changePassword
            ? `${t('User.ConfirmChangePassword') + email}?`
            : blocked
            ? `${t('User.ConfirmUnBlock') + email}?`
            : `${t('User.ConfirmBlock') + email}?`
        }
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setChangePassword(false);
        }}
        onConfirm={changePassword ? changePasswordUser : blockorUnblockUser}
      />

      <ModalLoading isOpen={openLoading} onClose={() => setOpenLoading(false)}>
        <div className="text-center">
          <span className="text-center"> {t('User.LoadingChangePassword') + email} </span>
        </div>
      </ModalLoading>

      <ModalSuccess isOpen={openSuccess} onClose={() => setOpenSuccess(false)}>
        <div className="text-center">
          <span className="text-center"> {t('User.EmailSent') + email} </span>
        </div>
      </ModalSuccess>

      <UserEditModal
        isOpen={openEdit}
        oldAccount={oldAccount}
        onClose={() => setOpenEdit(false)}
        fetchUsers={fetchUsers}
        email={email}
        clients={clients}
        clientValue={client ? { value: client, label: clientsObjects ? clientsObjects[client] : '' } : undefined}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  const { userList } = state;
  const { users } = userList;
  return { users };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  const { fetchUsers } = usersOperations;
  const { IncrementEvent } = tableServerRevision;

  return {
    fetchUsers: () => dispatch(fetchUsers()),
    IncrementEvent: (event: string) => dispatch(IncrementEvent(event)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
