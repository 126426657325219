import { useTranslation } from 'react-i18next';

import { ButtonNew, ModalComponent } from '@Components';

interface ConfirmationProvider {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmText: string;
}

const ConfirmationModal = ({ isOpen, onClose, onConfirm, confirmText }: ConfirmationProvider) => {
  const { t } = useTranslation();

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title="">
      <div className="flex flex-col">
        <span className="text-black-100 text-center">{confirmText}</span>
        <div className="flex gap-2 justify-center mt-8">
          <ButtonNew onClick={onClose} isSubmit={false} className="w-full bg-white">
            {t('Global.Canceled')}
          </ButtonNew>

          <ButtonNew onClick={onConfirm} variant="primary" isSubmit={false} className="w-full">
            {t('Global.Confirm')}
          </ButtonNew>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ConfirmationModal;
