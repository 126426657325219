import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { loading } from '@Assets';
import { ModalComponent } from '@Components';
import { bopsApi } from '@Network';

interface ModalMfaProps {
  isOpen: boolean;
  id: string;
  onClose: () => void;
}

const ModalMfa = ({ isOpen, onClose, id }: ModalMfaProps) => {
  const [loadingMfa, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (!id) return;

    setLoading(true);
    setMessage('');
    setError('');
    bopsApi
      .getMFACode(id)
      .then((res) => {
        setLoading(false);
        setMessage(res);
      })

      .catch((err) => {
        setLoading(false);
        setError(err.response?.data?.detail);
      });
  }, [id]);

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title={t('Mfa.TitleModal')}>
      <div className="pr-4 pl-4 min-h-[70px]">
        <div className="flex w-full justify-center h-full">
          {loadingMfa && (
            <div className="absolute">
              <img src={loading} alt="loading" className="w-[40px] text-gray-100 ml-auto animate-spin" />
            </div>
          )}
          {error && <span className="text-red p-4"> {error}</span>}
          {message && <span className="p-4"> {message}</span>}
        </div>
      </div>
    </ModalComponent>
  );
};

export default ModalMfa;
