import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '@Helpers';

import { ButtonNew, CredentialCardProps, Input, ModalComponent, ModalLoading, ModalSuccess } from '@Components';
import { bopsApi } from '@Network';

interface CredentialsModalProps {
  isOpen: boolean;
  onClose: () => void;
  credential: CredentialCardProps | undefined;
  setloading: (value: boolean) => void;
  credentialUpdated: () => void;
}

interface FormFields {
  accountEmail: string;
  accountPassword: string;
  token?: string;
  account: string;
}

const CredentialsModal = ({ isOpen, onClose, credential, setloading, credentialUpdated }: CredentialsModalProps) => {
  const { t } = useTranslation();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFields>();

  useEffect(() => {
    reset();
  }, [isOpen]);

  const onSubmit = (data: FormFields) => {
    const { accountEmail, accountPassword, token, account } = data;
    setOpenLoading(true);
    bopsApi
      .postUpdateCredentials({
        connectorType: credential?.credential?.connectorType || '',
        accountEmail: accountEmail ?? '',
        accountPassword: accountPassword ?? '',
        token: token ?? '',
        account: account ?? '',
      })
      .then(() => {
        credentialUpdated();
        onClose();
        setOpenLoading(false);
        setOpenSuccess(true);
        setloading(false);
      })
      .catch((error) => {
        setOpenLoading(false);
        setloading(false);
        showErrorToast(t('Credentials.Error'));
      });
  };

  return (
    <>
      <ModalComponent isOpen={isOpen} onClose={onClose} title={credential?.name || t('Credentials.TitleModal')}>
        <div className="w-full">
          <form onSubmit={handleSubmit(onSubmit)}>
            {credential?.showUsername && (
              <>
                <div className="pr-[20px]">
                  <span className="text-black-200 text-sm" id="accountEmail">
                    {`${t('Credentials.Username')}*`}
                  </span>
                  <Input
                    type="text"
                    id="accountEmail"
                    defaultValue={credential?.credential?.accountEmail}
                    className={`${errors?.accountEmail ? 'border-red focus:border-red' : 'focus:border-gray-400'} mb-2`}
                    {...register('accountEmail', {
                      required: `${t('Credentials.Username')} ${t('Global.Required')}`,
                    })}
                  />
                </div>
                {errors?.accountEmail && <p className="text-sm text-red">{errors?.accountEmail?.message}</p>}
              </>
            )}
            {credential?.showPassword && (
              <>
                <div className="pr-[20px]">
                  <span className="text-black-200 text-sm" id="accountPassword">
                    {`${t('Credentials.Password')}*`}
                  </span>
                  <Input
                    type="text"
                    id="accountPassword"
                    defaultValue={credential?.credential?.accountPassword}
                    className={`${
                      errors?.accountPassword ? 'border-red focus:border-red' : 'focus:border-gray-400'
                    } mb-2`}
                    {...register('accountPassword', {
                      required: `${t('Credentials.Password')} ${t('Global.Required')}`,
                    })}
                  />
                </div>
                {errors?.accountPassword && <p className="text-sm text-red">{errors?.accountPassword?.message}</p>}
              </>
            )}
            {credential?.showToken && (
              <>
                <div className="pr-[20px]">
                  <span className="text-black-200 text-sm" id="token">
                    {`${t('Credentials.Token')}*`}
                  </span>
                  <Input
                    type="text"
                    id="token"
                    defaultValue={credential?.credential?.token}
                    className={`${errors?.token ? 'border-red focus:border-red' : 'focus:border-gray-400'} mb-2`}
                    {...register('token', {
                      required: `${t('Credentials.Token')} ${t('Global.Required')}`,
                    })}
                  />
                </div>
                {errors?.token && <p className="text-sm text-red">{errors?.token?.message}</p>}
              </>
            )}
            {credential?.showAccount && (
              <>
                <div className="pr-[20px]">
                  <span className="text-black-200 text-sm" id="token">
                    {`${t('Credentials.Account')}*`}
                  </span>
                  <Input
                    type="text"
                    id="account"
                    defaultValue={credential?.credential?.account}
                    className={`${errors?.account ? 'border-red focus:border-red' : 'focus:border-gray-400'} mb-2`}
                    {...register('account', {
                      required: `${t('Credentials.Account')} ${t('Global.Required')}`,
                    })}
                  />
                </div>
                {errors?.account && <p className="text-sm text-red">{errors?.account?.message}</p>}
              </>
            )}

            <div className="flex gap-2 justify-center mt-4 w-full">
              <ButtonNew
                onClick={() => {
                  onClose();
                }}
                className="w-full bg-white"
                isSubmit={false}>
                {t('Global.Canceled')}
              </ButtonNew>
              <ButtonNew isSubmit variant="primary" className="w-full">
                <div className="text-white"> {t('Credentials.Update')} </div>
              </ButtonNew>
            </div>
          </form>
        </div>
      </ModalComponent>
      <ModalSuccess isOpen={openSuccess} onClose={() => setOpenSuccess(false)}>
        <div className="text-center">
          <span className="font-semibold text-[18px] text-center"> </span>
          <span className="text-[18px]"> {t('Credentials.UpdateSuccess')} </span>
        </div>
      </ModalSuccess>
      <ModalLoading isOpen={openLoading} onClose={() => setOpenLoading(false)}>
        <div className="text-center -mt-[9px]">
          <span className="text-center"> {t('Credentials.LoadingModal', { name: credential?.name })} </span>
        </div>
      </ModalLoading>
    </>
  );
};

export default CredentialsModal;
